<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="settingForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="settingForm narrow-form">
        <div class="row">
          <div class="col-md-12 mt-3">
            <validation-provider rules="required" v-slot="{ errors }" name="OptoutMessage">
              <TextareaEmojiPicker v-model="setting.opt_out_msg" label="Opt Out Message" :focused="true" :rows="3"
                ref="textareaEmojiOptout" :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false"
                :hideContactMergeFields="true" />
              <small v-show="errors.length" class="help text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="col-md-12 mt-4">
            <label class="input-label has-tool-tip my-2">Text Reply (SMS) <a v-b-tooltip.hover
                  title="This reply will be sent out when a customer contacts the business via text or the widget outside of business hours."
                  class="fa fa-question-circle tooltip-icon" /></label>
            <vuestic-switch v-model="setting.auto_reply_enabled" :offcolor=true style="max-width: 200px; margin-bottom: 20px;">
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
            <TextareaEmojiPicker v-model="setting.auto_reply" :focused="true" :rows="3"
              ref="textareaEmojiAutoReply" :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false"
              :hideContactMergeFields="true" />
          </div>
          <!-- <div class="col-md-12 mt-4">
            <div class="mb-4">
              <label class="input-label has-tool-tip">Text Reply (Call) <a v-b-tooltip.hover
                  title="This reply will be sent out when a customer requests a call to the business via widget outside of business hours."
                  class="fa fa-question-circle tooltip-icon" /></label>
              <vuestic-switch v-model="setting.auto_reply_call_enabled" :offcolor=true style="max-width: 200px">
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch>
            </div>
            <TextareaEmojiPicker v-if="setting.auto_reply_call_enabled" v-model="setting.auto_reply_call" label=""
              :focused="true" :rows="3" ref="textareaEmojiAutoReplyCall" :maxLength="maxLength" :showSendButton="false"
              :hasHoverEffect="false" :hideContactMergeFields="true" />
          </div> -->
          <div class="col-md-12 mt-4">
            <div class="mb-1">
              <label class="input-label has-tool-tip">Voice Reply (Call) <a v-b-tooltip.hover
                  title="This voice will be played when a customer directly calls the business outside of business hours. Default voice will be played unless it's set."
                  class="fa fa-question-circle tooltip-icon" /></label>
              <!-- <vuestic-switch v-model="setting.auto_reply_call_voice_enabled" :offcolor=true style="max-width: 200px">
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch> -->
            </div>
            <VoiceRecording v-if="setting.auto_reply_call_voice_enabled" label=""
              v-model="setting.auto_reply_call_voice" />
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" name="schedule" id="enableVoicemail" :value="true"
                v-model="setting.auto_reply_call_voicemail_enabled" />
              <label for="enableVoicemail">
                <span class="abc-label-text" style="top: 0">Enable VoiceMail</span> <a v-b-tooltip.hover
                  title="Contacts will be able to leave voicemail to the business"
                  class="fa fa-question-circle tooltip-icon pl-1" />
              </label>
            </div>
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" name="schedule" id="enableTranscription" :value="true"
                v-model="setting.transcription_enabled" />
              <label for="enableTranscription">
                <span class="abc-label-text" style="top: 0">Enable Voicemail Transcription</span> <a v-b-tooltip.hover
                  title="We will show transcription in inbox"
                  class="fa fa-question-circle tooltip-icon pl-1" />
              </label>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <TextareaEmojiPicker v-model="setting.new_contact_email_subject" label="New Contact Email Notification Subject" :focused="true" :rows="2"
              ref="textareaEmojiNewMesssageEmailSubject" :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false"
              tooltip="Subject of Email Notification when there is a new conatct request." />
          </div>
          <div class="col-md-12 mt-4">
            <div class="mb-4">
              <label class="input-label has-tool-tip my-2">Auto responder message (SMS) <a v-b-tooltip.hover
                  title="This reply will be sent out when a customer contacts the call only business via text."
                  class="fa fa-question-circle tooltip-icon" /></label>
              <vuestic-switch v-model="setting.auto_responder_sms_enabled" :offcolor=true style="max-width: 200px">
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch>
            </div>
            <TextareaEmojiPicker v-if="setting.auto_responder_sms_enabled" v-model="setting.auto_responder_sms"
              label="Auto responder message (SMS)" :focused="true" :rows="3" ref="textareaEmojiSms" :maxLength="maxLength"
              :showSendButton="false" :hasHoverEffect="false" :hideContactMergeFields="true" />
          </div>
          <div class="col-md-12 mt-4">
            <div class="mb-4">
              <label class="input-label has-tool-tip my-2">Auto responder message (Call) <a v-b-tooltip.hover
                  title="This reply will be sent out when a customer contacts the text only business via call."
                  class="fa fa-question-circle tooltip-icon" /></label>
              <vuestic-switch v-model="setting.auto_responder_call_enabled" :offcolor=true style="max-width: 200px">
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch>
            </div>
            <TextareaEmojiPicker v-if="setting.auto_responder_call_enabled" v-model="setting.auto_responder_call"
              label="Auto responder message (Call)" :focused="true" :rows="3" ref="textareaEmojiCall"
              :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false" :hideContactMergeFields="true" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid" style="min-width: 80px">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto" />
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import TextareaEmojiPicker from "../../common/TextareaEmojiPicker";
import VoiceRecording from "../../common/VoiceRecording"

export default {
  name: "business-messagge",
  components: {
    TextareaEmojiPicker, VoiceRecording,
  },
  data() {
    return {
    };
  },
  mounted() { },
  computed: {
    ...mapGetters("auth", {
      setting: "businessSetting",
    }),
    ...mapState("business", {
      loading: "loading",
      processing: "processing",
    }),

    maxLength() {
      return 255;
    },
  },
  methods: {
    onFormSubmit() {
      const params = {
        ...this.setting,
      };
      this.$store
        .dispatch("business/updateSetting", params)
        .then((res) => { })
        .catch((err) => { });
    },
  },
};
</script>
<style lang="scss"></style>
