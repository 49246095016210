<template>
<vuestic-widget>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-center" v-if="loading">
        <span class="atom-spinner-wrapper">
          <atom-spinner slot="processsing" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
        </span>
      </div>
      <div class="col-md-12" v-else>
        <div class="row">
          <div class="col-md-12 text-right mb-3">
            <button
              class="btn btn-sm btn-primary mr-2" @click="onCreate"
            >
              <span><i class="fa fa-plus"></i> New Number</span>
            </button>
          </div>
          <div class="col-md-12">
            <datatable v-bind="this.tableConfig" class="le-datatable"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vuestic-modal
    :isOpen="isOpenModalEdit"
    @cancel="closeModalEdit"
    :cancelShown="false"
    :okShown="false"
  >
    <span slot="title">{{ isEdit ? 'Edit Number': 'Create New Number' }}</span>
    <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
      <form @submit.prevent="handleSubmit(handleCreate)">
        <div class="row">
          <div class="col-md-12">
            <p class="font-weight-bold">In order to be able to send a broadcast to your subscribers from your mobile phone you will need to add your mobile phone number below.</p>
            <p class="font-weight-bold">To broadcast from your cell phone simply send the word broadcast followed by your message to your install number.</p>
            <p class="font-weight-bold">To broadcast to specific tags simply text the word broadcast (tag1, tag2) followed by your message to your install number.</p>
          </div>
          <div class="col-md-12">
            <text-input name="name" v-model="submitData.name" label="Name"/>
          </div>
          <div class="col-md-12">
            <phone-number-input name="Phone Number" v-model="submitData.number" label="Phone Number" :validate="'required'"/>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-2" @click="closeModalEdit">Cancel</button>
            <button class="btn btn-primary" :disabled="processsing || invalid" style="min-width: 80px;">
              <atom-spinner v-if="processsing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>{{!isEdit ? 'Create' : 'Update'}}</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
  <vuestic-modal 
    :isOpen="isOpenModalDelete"
    :hideDefaultActions="true"
    @cancel="closeModalDelete"
    okText="Delete"
    :cancelShown="false"
    @ok="handleDelete"
    okClass="btn btn-danger btn-primary"
  >
    <span slot="title" class="text-danger">Delete Number</span>
    <p>Are you sure you want to delete this number <strong>{{ submitData ? submitData.number : '' }}</strong> ?</p>
  </vuestic-modal>
</vuestic-widget>
</template>

<script>
  import Vue from 'vue';
  import components from "../../../common/tables/comps";
  import More from './Actions/More'
  import { mapState } from 'vuex';

  export default {
    components: { More },
    data() {
      return {
        isEdit: false,
        loading: false,
        processsing: false,
        isOpenModalEdit: false,
        isOpenModalDelete: false,
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: "table-bordered",
          tblStyle: "color: #666",
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            return [
              { title: "Name", field: "name", },
              { title: "Phone Number", field: "phone_number", tdComp: 'TdPhone', },
              { title: '', tdComp: More, visible: 'true', tdClass: 'center'},
            ];
          })(),
          data: [],
          total: 0,
          // selection: [],  //if this is present the row selector shows up..
          summary: {},
          query: {},
          // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
          xprops: {
            eventbus: new Vue()
          }
        },
        submitDataOrg: {
          id: null,
          name: '',
          number: '',
        },
        submitData: {
          id: null,
          name: '',
          number: '',
        }
      };
    },

    created () {
      this.submitData = Vue.util.extend({}, this.submitDataOrg)

      this.tableConfig.xprops.eventbus.$on('openEditModal', row => {
        this.isEdit = true
        this.submitData = {
          id: row.id,
          name: row.name,
          number: row.phone_number
        }

        this.isOpenModalEdit = true
      })

      this.tableConfig.xprops.eventbus.$on('openDeleteModal', row => {
        this.submitData = {
          id: row.id,
          name: row.name,
          number: row.phone_number
        }
        this.isOpenModalDelete = true
      })
    },

    watch: {
      'tableConfig.query': {
        handler() {
          this.loadData();
        },
        deep: true
      }
    },

    computed: {
    },
    mounted() {
    },
    methods: {
      loadData() {
        const { query } = this.tableConfig;
        const page = query.offset ? Math.floor(query.offset /  query.limit) + 1 : 1;
        const param = {
          page,
          per_page: query.limit,
          order: query.order,
          sort: query.sort
        }

        this.loading = true;

        this.$store.dispatch('broadcast/fetchBroadcasters', param)
          .then((data) => {
            this.tableConfig.data = data.data
            this.tableConfig.total = data.meta.total;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          })
      },
      
      onCreate() {
        this.submitData = Vue.util.extend({}, this.submitDataOrg)
        this.isEdit = false;
        this.isOpenModalEdit = true;
      },

      closeModalEdit() {
        this.isOpenModalEdit = false;
      },

      closeModalDelete() {
        this.isOpenModalDelete = false;
      },

      handleDelete() {
        this.$store
          .dispatch('broadcast/deleteBroadcaster', this.submitData.id)
          .then(() => {
            this.isOpenModalDelete = false;
            this.loadData();
          })
          .catch((err) => {
          })
      },

      handleCreate() {
        if (this.isEdit)
        {
          const param = {
            id: this.submitData.id,
            data: this.submitData
          }
          this.processsing = true;
          this.$store
            .dispatch('broadcast/updateBroadcaster', param)
            .then(() => {
              this.processsing = false;
              this.isOpenModalEdit = false;
              this.loadData();
            })
            .catch((err) => {
            })
        } else {
          const param = {
            name: this.submitData.name,
            number: this.submitData.number
          }
          this.processsing = true;
          this.$store
            .dispatch('broadcast/createBroadcaster', param)
            .then(() => {
              this.processsing = false;
              this.isOpenModalEdit = false;
              this.loadData();
            })
            .catch((err) => {
            })
        }
      },
    },

  };
</script>

<style lang="scss" scoped>
  
</style>
