<template>
  <vuestic-widget>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="loading">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="processsing" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <div class="col-md-12" v-else>
          <div class="row">
            <div class="col-md-12 text-right mb-3">
              <button class="btn btn-sm btn-primary mr-2" @click="onCreate">
                <span><i class="fa fa-plus"></i> Add Field</span>
              </button>
            </div>
            <div class="col-md-12">
              <datatable v-bind="this.tableConfig" class="le-datatable" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal :isOpen="isOpenModalEdit" @cancel="closeModalEdit" :cancelShown="false" :okShown="false">
      <span slot="title">{{ isEdit ? 'Edit Field' : 'Add Field' }}</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
        <form @submit.prevent="handleSubmit(handleCreate)">
          <div class="row">
            <div class="col-md-12">
              <text-input name="name" v-model="submitData.name" label="Name" validate="required" />
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <div class="input-group has-margin-top">
                  <label class="control-label">Type</label>
                  <multiselect v-model="fieldTypeSelected" :options="fieldTypes" :close-on-select="true"
                    :show-labels="false" :searchable="false" :multiple="false" :allow-empty="false"
                    :preselect-first="true" label="label" track-by="id"></multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <text-input name="order" v-model="submitData.order" label="Order" />
            </div>
            <div v-if="isEdit" class="col-md-12">
              <vuestic-switch v-model="submitData.is_active" :offcolor="true" style="width: 200px">
                <span slot="trueTitle">Active</span>
                <span slot="falseTitle">Inactive</span>
              </vuestic-switch>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button type="button" class="btn btn-danger mr-2" @click="closeModalEdit">Cancel</button>
              <button class="btn btn-primary" :disabled="processsing || invalid" style="min-width: 80px;">
                <atom-spinner v-if="processsing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>{{ !isEdit ? 'Create' : 'Update' }}</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" okText="Delete"
      :cancelShown="false" @ok="handleDelete" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger">Delete Field</span>
      <p>Are you sure you want to delete this field <strong>{{ submitData ? submitData.name : '' }}</strong> ?</p>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import Vue from 'vue';
import components from "../../../common/tables/comps";
import More from './Actions/More'
import { mapState } from 'vuex';
import ActiveToggleTd from '@/components/common/tables/comps/Accounts/ActiveToggleTd'

export default {
  components: { More },
  data() {
    return {
      isEdit: false,
      loading: false,
      processsing: false,
      isOpenModalEdit: false,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "Name", field: "name" },
            { title: 'Type', field: 'type_label', tdClass: 'center' },
            { title: 'Order', field: 'order', tdClass: 'center' },
            { title: "Active", field: "is_active", tdComp: ActiveToggleTd },
            { title: 'Actions', tdComp: More, visible: 'true', tdClass: 'center' },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      submitDataOrg: {
        id: null,
        name: '',
        is_active: true,
        type: 'String',
        order: 1,
      },
      submitData: {
        id: null,
        name: '',
        is_active: true,
        type: 'String',
        order: 1,
      },
      fieldTypes: [
        {id: 1, label: 'String'},
        {id: 2, label: 'Text'},
        {id: 3, label: 'Date'},
      ],
      fieldTypeSelected: undefined,
    };
  },

  created() {
    this.submitData = Vue.util.extend({}, this.submitDataOrg)

    this.tableConfig.xprops.eventbus.$on('openEditModal', row => {
      this.isEdit = true
      this.submitData = {
        id: row.id,
        name: row.name,
        is_active: row.is_active,
        type: row.type,
        order: row.order,
      }

      this.fieldTypeSelected = this.fieldTypes.find(item => item.id === row.type)
      this.isOpenModalEdit = true
    })

    this.tableConfig.xprops.eventbus.$on('toggleActive', row => {
      this.isEdit = true
      this.submitData = this.tableConfig.data.find(item => item.id === row.id)
      this.submitData.is_active = row.active
      this.handleCreate()
    })
    
    this.tableConfig.xprops.eventbus.$on('openDeleteModal', row => {
      this.submitData = {
        id: row.id,
        name: row.name,
      }
      this.isOpenModalDelete = true
    })

    this.fieldTypeSelected = this.fieldTypes[0]
  },

  watch: {
    'tableConfig.query': {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },

  computed: {
  },
  methods: {
    loadData() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort
      }

      this.loading = true;

      this.$store.dispatch('contact/fetchFields', param)
        .then((data) => {
          this.tableConfig.data = data.data
          this.tableConfig.total = data.meta.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        })
    },

    onCreate() {
      this.submitData = Vue.util.extend({}, this.submitDataOrg)
      this.isEdit = false;
      this.isOpenModalEdit = true;
    },

    closeModalEdit() {
      this.isOpenModalEdit = false;
    },

    closeModalDelete() {
      this.isOpenModalDelete = false;
    },

    handleDelete() {
      this.$store
        .dispatch('contact/deleteField', this.submitData.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.$store.dispatch('auth/getProfile')
          this.loadData();
        })
        .catch((err) => {
        })
    },

    handleCreate() {
      this.submitData.type = this.fieldTypeSelected ? this.fieldTypeSelected.id : undefined
      if (this.isEdit) {
        this.processsing = true
        this.$store
          .dispatch('contact/updateField', this.submitData)
          .then(() => {
            this.processsing = false
            this.isOpenModalEdit = false
            this.$store.dispatch('auth/getProfile')
            this.loadData()
          })
          .catch(() => {
            this.processsing = false
          })
      } else {
        this.processsing = true
        this.$store
          .dispatch('contact/createField', this.submitData)
          .then(() => {
            this.processsing = false
            this.isOpenModalEdit = false
            this.$store.dispatch('auth/getProfile')
            this.loadData()
          })
          .catch(() => {
            this.processsing = false
          })
      }
    },
  },

};
</script>

<style lang="scss" scoped></style>
