<template>
  <div>
    <ValidationObserver
      v-slot="{ handleSubmit, invalid }"
      ref="userCreateForm"
    >
      <form
        @submit.prevent="handleSubmit(onFormSubmit)"
        class="analyticForm narrow-form"
      >
        <div class="row">
          <div class="col-md-12">
            <label class="lelabel">Installs: </label>
            <multiselect
              class="install-select d-inline-block"
              style="min-width: 400px"
              v-model="selectedInstall"
              :options="optionInstalls"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Please Select Install"
              label="label"
              track-by="label"
              :preselect-first="false"
              :show-labels="false"
              :allowEmpty="false"
              @input="onChangeInstall"
            ></multiselect>
          </div>
        </div>
        <div v-if="selectedInstall" class="row">
          <div v-if="loading" class="col-md-12 d-flex justify-content-center py-5">
            <atom-spinner
              slot="loading"
              :animation-duration="1500"
              :size="50"
              color="#3578c6"
            />
          </div>
          <template v-else>
            <div class="col-md-12 mt-4">
              <text-input
                name="TrackingId"
                v-model="formData.ga_tracking_code"
                label="Tracking ID"
                tooltip="The Google Analytics tracking ID which is a unique identifier Google Analytics assigns to a property"
                help="Starting July 1, 2023, Universal Analytics properties will stop processing hits. We strognly suggest to use [GA4] Measurement ID instead."
              />
            </div>
            <div class="col-md-12 mt-4">
              <text-input
                name="MeasurementId"
                v-model="formData.measurement_id"
                label="Measurement ID"
                tooltip="A Measurement ID is an identifier (e.g., G-12345) for a web data stream."
                :validate="'required'"
              />
            </div>
            <div class="col-md-12 mt-3">
              <p><b>Category for goals</b>: CallWidget</p>
            </div>
            <div class="col-md-12 mt-3">
              <p class="font-weight-bold">Call Request</p>
              <p>Action: CW.requested.call</p>
              <p>Label: CW Requested Call</p>
              <vuestic-switch
                v-model="formData.requested_call"
                :offcolor="true"
              >
                <span slot="trueTitle">On</span>
                <span slot="falseTitle">Off</span>
              </vuestic-switch>
            </div>
            <div class="col-md-12 mt-5">
              <p class="font-weight-bold">Text Request</p>
              <p>Action: CW.requested.text</p>
              <p>Label: CW Requested Text</p>
              <vuestic-switch
                v-model="formData.requested_text"
                :offcolor="true"
              >
                <span slot="trueTitle">On</span>
                <span slot="falseTitle">Off</span>
              </vuestic-switch>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <button
                class="btn btn-primary"
                :disabled="invalid"
                style="min-width: 80px"
              >
                <atom-spinner
                  v-if="processing"
                  slot="loading"
                  :animation-duration="1500"
                  :size="14"
                  color="#FFF"
                  style="margin: auto"
                />
                <span v-else>Update</span>
              </button>
            </div>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "analytic-integration",
  components: {
  },
  data() {
    return {
      loading: false,
      processing: false,
      installs: [],
      selectedInstall: undefined,
      formData: {
        ga_tracking_code: "",
        requested_call: true,
        requested_text: true,
      },
    };
  },
  mounted() {
    this.loadInstall();
  },
  computed: {
    ...mapGetters("auth", {
      setting: "businessSetting",
    }),
    optionInstalls() {
      return this.installs.map((item) => {
        return {
          id: item.id,
          number: item.available_number,
          label:
            this.formatPhone(item.available_number) +
            " : " +
            item.website,
        };
      });
    },
  },
  methods: {
    formatPhone(val) {
      if (!val) return "";
      if (!val.startsWith("+")) val = "+" + val;

      const phoneNumber = parsePhoneNumberFromString(val);
      return phoneNumber.formatNational();
    },

    onChangeInstall() {
      this.loading = true
      this.$store
        .dispatch("install/fetchInstall", this.selectedInstall.id)
        .then(({ data }) => {
          const { settings } = data
          this.formData = {
            ga_tracking_code: settings.trackingInfo
              ? settings.trackingInfo.ga_tracking_code
              : '',
            measurement_id: settings.trackingInfo
              ? settings.trackingInfo.measurement_id
              : '',
            requested_call: settings.trackingInfo
              ? settings.trackingInfo.requested_call
              : true,
            requested_text: settings.trackingInfo
              ? settings.trackingInfo.requested_text
              : true,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    loadInstall() {
      const param = {
        status: "active",
      };
      this.$store
        .dispatch("install/fetchAllInstalls", param)
        .then(({ data }) => {
          this.installs = data;
          if (this.installs.length)
          {
            this.selectedInstall = this.optionInstalls[0]
            this.onChangeInstall()
          }
        })
        .catch(() => {
        });
    },
    onFormSubmit() {
      if (this.selectedInstall)
      {
        this.processing = true;
        const params = {
          id: this.selectedInstall.id,
          data: {
            trackingInfo: this.formData,
          }
        };

        this.$store
          .dispatch("install/updateSetting", params)
          .then((res) => {
            this.processing = false;
          })
          .catch((err) => {
            this.processing = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.vuestic-switch {
  max-width: 200px;
  width: 200px;
}
</style>

      