<template>
  <div class="setting-page">
    <vuestic-widget :loading="loading">
      <template slot="header">
        <span class="title">Settings</span>
      </template>
      <div>
        <b-tabs small card>
          <b-tab v-if="isBusinessManager" title="Information" :active="tabIndex == 1">
            <Information/>
          </b-tab>
          <b-tab v-if="isBusinessManager" title="Integrations" :active="tabIndex == 2">
            <Integrations/>
          </b-tab>
          <b-tab title="Notification" :active="tabIndex == 3">
            <Notification/>
          </b-tab>
          <b-tab v-if="isBusinessManager" title="Messages" :active="tabIndex == 4">
            <Messages/>
          </b-tab>
          <b-tab v-if="isBusinessManager" title="Analytics" :active="tabIndex == 5">
            <AnalyticIntegration/>
          </b-tab>
          <b-tab v-if="isBusinessManager" title="Broadcast Numbers" :active="tabIndex == 6">
            <BroadcastNumbers/>
          </b-tab>
          <b-tab v-if="isBusinessManager" title="Custom Fields" :active="tabIndex == 7">
            <Contact/>
          </b-tab>
        </b-tabs>
      </div>
    </vuestic-widget>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AnalyticIntegration from "./AnalyticIntegration";
import Notification from "./Notification";
import Integrations from "./Integration/Index";
import BroadcastNumbers from "./BroadcastNumber/Index";
import Messages from "./Messages";
import Information from "./Information";
import Contact from './Contact/Index.vue'

export default {
  name: "business-settings",
  components: {
    Integrations,
    AnalyticIntegration,
    Notification,
    BroadcastNumbers,
    Messages,
    Information,
    Contact
  },
  data() {
    return {
      tabIndex: 1,
    };
  },
  mounted() {
    if (this.$route.query.tab)
      this.tabIndex = this.$route.query.tab
  },
  computed: {
    ...mapGetters("auth", {
      setting: "businessSetting",
    }),
    ...mapState("business", {
      loading: "loading",
      processing: "processing",
    }),
    user() {
      return this.$store.state.auth.user;
    },
    isBusinessManager() {
      return this.$store.getters["auth/isBusinessManager"];
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">
  .setting-page {
    .widget-body {
      padding: 0;
    }
  }
</style>
