<template>
  <div class="integration-wrapper">
    <vuestic-widget :loading="fetching">
      <template v-if="false">
        <div v-for="(item, key) in googleIntegrations" :key="`gogoel${key}`" class="inner-wrapper mt-4 mb-2">
          <div class="row">
            <div class="col-md-4 d-flex">
              <img class="integraion-logo" src="@/assets/images/integrations/google-message.webp">
              <div>
                <span class="logo-title">Google Account</span>
                <span class="">({{ item.email }})</span>
              </div>
            </div>
            <div class="col-md-5">
              <span class="desc mr-3">Account is Connected!</span>
              <button class="btn btn-danger" style="min-width: 80px" @click.prevent="onGoogleDisconnect(item.id)">
                <atom-spinner v-if="processingGoogleItem == item.id" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" style="margin: auto" />
                <span v-else>Disconnect</span>
              </button>
            </div>
            <div class="col-md-3">
              <button v-if="key === googleIntegrations.length - 1" class="btn btn-primary" style="min-width: 80px"
                @click.prevent="onGoogleConnect">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto" />
                <span v-else>Add another account</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="!googleIntegrations.length" class="inner-wrapper mt-4 mb-2">
          <div class="row">
            <div class="col-md-4">
              <img class="integraion-logo" src="@/assets/images/integrations/google-message.webp">
              <span class="logo-title">Google Account</span>
            </div>
            <div class="col-md-6">
              <span class="desc">Connect your Google Workspace email account to send emails from within our
                platform.</span>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary" style="min-width: 80px" @click.prevent="onGoogleConnect">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto" />
                <span v-else>Connect</span>
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-if="false">
        <div v-for="(item, key) in googleLocations" :key="`location${key}`">
          <div v-for="(install, key1) in item.installs" :key="key1" class="row inner-wrapper mt-4 mb-2">
            <div class="col-md-4">
              <img class="integraion-logo" src="@/assets/images/integrations/gmb.webp">
              <span class="logo-title">Google My Business</span>
            </div>
            <div class="col-md-5 d-flex align-items-center">
              <span class="desc mr-3">{{ item.display_name }} - {{ install.number | phone }} </span>
              <div class="d-inline-block">
                <button class="btn btn-danger" style="min-width: 80px"
                  @click.prevent="onGoogleLocationDisconnect(item.id, install.id)">
                  <atom-spinner v-if="processingGmbItem == item.id" slot="loading" :animation-duration="1500" :size="14"
                    color="#FFF" style="margin: auto" />
                  <span v-else>Disconnect</span>
                </button>
                <button class="btn btn-success d-block mt-2" style="min-width: 80px"
                  @click.prevent="onGoogleLocationEdit(item.id)">
                  <span>Setting</span>
                </button>
              </div>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary" style="min-width: 80px" @click.prevent="onGoogleLocationConnect">
                Add Another
              </button>
            </div>
          </div>
        </div>
        <div v-if="!googleLocations.length" class="inner-wrapper mt-4 mb-2">
          <div class="row">
            <div class="col-md-4">
              <img class="integraion-logo" src="@/assets/images/integrations/gmb.webp">
              <span class="logo-title">Google My Business</span>
            </div>
            <div class="col-md-6">
              <span class="desc">Your messages from Google &amp; SMS in one inbox. Manage and respond to every message
                from one platform.</span>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary" style="min-width: 80px" @click.prevent="onGoogleLocationConnect">
                Select
              </button>
            </div>
          </div>
        </div>
      </template>
      <div v-for="(item, key) in emailAccounts" :key="`email${key}`" class="inner-wrapper mt-4 mb-2">
        <div class="row">
          <div class="col-md-4 d-flex align-items-center">
            <img class="integraion-logo" src="@/assets/images/integrations/inbox.webp">
            <div>
              <span class="logo-title">Email Account</span>
              <span class="">({{ item.email }})</span>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-center">
            <span class="desc mr-3">Account is Connected!</span>
            <div class="d-inline-block">
              <button class="btn btn-danger" style="min-width: 80px" @click.prevent="onEmailAccountDelete(item.id)">
                <atom-spinner v-if="processingEmailItem == item.id" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" style="margin: auto" />
                <span v-else>Delete</span>
              </button>
              <button class="btn btn-success d-block mt-2" style="min-width: 80px"
                @click.prevent="onEmailAccountEdit(item.id)">
                <span>Setting</span>
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <button v-if="key === emailAccounts.length - 1" class="btn btn-primary" style="min-width: 80px"
              @click.prevent="onAddEmailAccount">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto" />
              <span v-else>Add another account</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="!emailAccounts.length" class="inner-wrapper mt-4 mb-2">
        <div class="row">
          <div class="col-md-4">
            <img class="integraion-logo" src="@/assets/images/integrations/inbox.webp">
            <span class="logo-title">Email Account</span>
          </div>
          <div class="col-md-6">
            <span class="desc">Connect your Email account to send emails from within our platform.</span>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" style="min-width: 80px" @click.prevent="onAddEmailAccount">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto" />
              <span v-else>Connect</span>
            </button>
          </div>
        </div>
      </div>
      <div v-for="(item, key) in filteredUserIntegration" :key="`integration_${key}`" class="inner-wrapper mt-4 mb-2">
        <div class="row">
          <div class="col-md-4 d-flex align-items-center">
            <img class="integraion-logo" :src="item.integration.icon">
            <div>
              <span class="logo-title">{{item.integration.title}} Account</span>
              <span class="d-block">({{ item.name }})</span>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-center">
            <span class="desc mr-3">Account is Connected!</span>
            <div class="d-inline-block">
              <button class="btn btn-danger" style="min-width: 80px" @click.prevent="onDeleteIntegration(item.id)">
                <atom-spinner v-if="processingEmailItem == item.id" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" style="margin: auto" />
                <span v-else>Delete</span>
              </button>
              <button class="btn btn-success d-block mt-2" style="min-width: 80px"
                @click.prevent="onEditIntegration(item.id)">
                <span>Setting</span>
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <button v-if="key === userIntegrations.length - 1" class="btn btn-primary" style="min-width: 80px"
              @click.prevent="onAddIntegration(item.integration_id)">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto" />
              <span v-else>Add another account</span>
            </button>
          </div>
        </div>
      </div>
      <div v-for="(item, key) in createIntegrationTypes" :key="`create${key}`" class="inner-wrapper mt-4 mb-2">
        <div class="row">
          <div class="col-md-4">
            <img class="integraion-logo" :src="item.icon">
            <span class="logo-title">{{ item.title }} Account</span>
          </div>
          <div class="col-md-6">
            <span class="desc">{{ item.create_description }}</span>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" style="min-width: 80px" @click.prevent="onAddIntegration(item.id)">
              <span>Connect</span>
            </button>
          </div>
        </div>
      </div>
    </vuestic-widget>
    <vuestic-modal :isOpen="showNewEmailAcctModal" @cancel="showNewEmailAcctModal = false" :okShown="false"
      :cancelShown="false" :force="true" class="new-email-account-modal">
      <span slot="title" class="text-primary font-weight-bold">Setup Email Account for Sending</span>
      <div class="provider-section d-flex align-items-center">
        <div v-for="(item, key) in emailProviders" :key="key"
          :class="{ 'active': selectedProvider && item.id === selectedProvider.id }"
          class="provider-item d-flex align-items-center justify-content-center" @click="selectProvider(item)">
          <p><i class="fa fa-paper-plane" aria-hidden="true"></i>{{ item.label }}</p>
        </div>
      </div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalDelete" @ok="handleDelete" @cancel="isOpenModalDelete = false" okText="Delete"
      cancelText="Cancel" :closeOnOk="false" :processing="processing" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Delete Integration</span>

      <div>Confirm you want to delete this integration?</div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "integrations",
  components: {
  },
  data() {
    return {
      showNewEmailAcctModal: false,
      selectedProvider: null,
      processingGoogleItem: null,
      processingGmbItem: null,
      processingEmailItem: null,
      isOpenModalDelete: false,
      integrationType: null,
      selectedId: null,
      integrationTypes: [],
    };
  },

  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters("auth", {
      sendgridEnabled: "sendgridEnabled",
    }),

    ...mapState('businessIntegration', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
      googleIntegrations: "googleIntegrations",
      googleLocations: "googleLocations",
      emailAccounts: "emailAccounts",
      emailProviders: "emailProviders",
      userIntegrations: "userIntegrations",
    }),

    createIntegrationTypes() {
      let userTypeIds = this.userIntegrations.map(item => item.integration_id)
      let result = this.integrationTypes.filter(item => !userTypeIds.includes(item.id))
      if (!this.sendgridEnabled)
        result = result.filter(item => item.key !== 'sendgrid')
      return result
    },

    filteredUserIntegration() {
      let result = this.userIntegrations
      if (!this.sendgridEnabled)
        result = result.filter(item => item.key !== 'sendgrid')
      return result
    }
  },
  methods: {
    initData() {
      this.$store
        .dispatch("businessIntegration/types")
        .then((res) => {
          this.integrationTypes = res
          this.loadIntegrations()
        })
    },

    loadIntegrations() {
      this.$store
        .dispatch("businessIntegration/googleAll")

      this.$store
        .dispatch("businessIntegration/googleLocationAll")

      this.$store
        .dispatch("businessIntegration/emailAccountAll")

      this.$store
        .dispatch("businessIntegration/list")
    },

    handleDelete() {
      switch (this.integrationType) {
        case 'Email':
          this.deleteEmailAccount(this.selectedId);
          break;
        default:
          this.deleteIntegration(this.selectedId);
          break;
      }
    },

    onAddIntegration(id) {
      this.$router.push({ name: 'business.integration.create', query: { id } })
    },

    onEditIntegration(id) {
      this.$router.push({ name: 'business.integration.edit', query: { id } })
    },

    onDeleteIntegration(id) {
      this.selectedId = id
      this.isOpenModalDelete = true
    },

    deleteIntegration(id) {
      this.$store
        .dispatch("businessIntegration/delete", id)
        .then(() => {
          this.isOpenModalDelete = false
        })
        .catch((err) => {
          this.isOpenModalDelete = false
        });
    },

    onAddEmailAccount() {
      this.selectedProvider = null
      this.showNewEmailAcctModal = true
    },

    selectProvider(item) {
      if (item.id === 'other') {
        this.$router.push({ name: 'business.smtp.create' })
      }
    },

    onEmailAccountEdit(id) {
      this.$router.push({ name: 'business.smtp.edit', params: { id } })
    },

    onEmailAccountDelete(id) {
      this.isOpenModalDelete = true
      this.integrationType = 'Email'
      this.selectedId = id
    },

    deleteEmailAccount(id) {
      this.$store
        .dispatch("businessIntegration/emailAccountDelete", id)
        .then(() => {
          this.isOpenModalDelete = false
        })
        .catch((err) => {
          this.isOpenModalDelete = false
        });
    },

    onGoogleLocationEdit(id) {
      this.$router.push({ name: 'business.location.edit', params: { id } })
    },

    onGoogleDisconnect(id) {
      this.processingGoogleItem = id
      this.$store
        .dispatch("businessIntegration/googleDelete", id)
        .then(() => {
          this.processingGoogleItem = null
        })
        .catch((err) => {
          this.processingGoogleItem = null
        });
    },

    async onGoogleConnect() {
      const authCode = await this.$gAuth.getAuthCode()

      const param = {
        code: authCode
      }

      this.$store
        .dispatch("businessIntegration/googleCreate", param)
    },

    onGoogleLocationConnect() {
      this.$router.push({ name: 'business.location.create' })
    },

    onGoogleLocationDisconnect(id, installId) {
      this.processingGmbItem = id
      const param = {
        id,
        installId
      }
      this.$store
        .dispatch("businessIntegration/googleLocationDelete", param)
        .then(() => {
          this.processingGmbItem = null
        })
        .catch((err) => {
          this.processingGmbItem = null
        });
    },

    onGoogleLocationChange($id) {

    },
  },
};
</script>
<style lang="scss">
.new-email-account-modal {
  .provider-section {
    .provider-item {
      width: 120px;
      height: 120px;
      border: 2px solid $rb-blue;
      border-radius: 10px;
      font-weight: bold;
      color: $rb-blue;
      cursor: pointer;

      &:hover {
        background-color: $rb-blue;
        color: #fff;
      }

      p {
        padding: 0;
        margin: 0;
        text-align: center;

        i {
          display: block;
          font-size: 36px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.integration-wrapper {
  max-width: 1024px;

  .inner-wrapper {
    padding-bottom: 20px;
    border-bottom: 2px solid $rb-blue;

    >div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .integraion-logo {
      width: 60px;
      margin-right: 15px;
    }

    .logo-title {
      display: inline-block;
      font-weight: bold;
      font-size: 25px;
    }

    span.desc {
      font-weight: 600;
    }

    .smtp-logo {
      padding: 0 20px;
      font-weight: bold;
      color: #1f76d0;
      text-align: center;

      i {
        font-size: 50px;
      }
    }
  }
}
</style>

      